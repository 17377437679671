.googleForm {
    border: none;
    width: 600px;
    height: 100%;
}

@media screen and (max-width: 700px) {
    div .googleForm {
        width: 90%;
    }
}
