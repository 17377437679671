.gallery {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
}

.imagePreview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    flex-direction: column;
    align-items: center;
}

.imagePreview .description {
    background-color: #fcf8ef;
    padding: 10px 0;
    width: 90%;
}

.imagePreview img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    opacity: 1;
}
