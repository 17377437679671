@font-face {
  font-family: font;
  src: url('./../public/font.otf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: font;
  background-color: #fcf8ef;
}

html, body, #root {
  height: 100%;
  width: 100%;
}
