strong {
    font-weight: 600;
}

#content {
    margin-top: 50px;
    text-align: center;
}

/* Timeline */

.timeline {
    border-left: 4px solid #f5c001;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin: 50px auto;
    letter-spacing: 0.5px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 30px 50px;
    list-style: none;
    text-align: left;
    font-weight: 100;
    max-width: 30%;

    h1, h2, h3 {
        letter-spacing: 1.5px;
        font-weight: 100;
        font-size: 1.4em;
    }

    .event {
        border-bottom: 1px dashed #999;
        padding-bottom: 5px;
        margin-bottom: 10px;
        position: relative;

        &:last-of-type {
            padding-bottom: 0;
            margin-bottom: 0;
            border: none;
        }

        &:before, &:after {
            position: absolute;
            display: block;
            top: 0;
        }

        &:before {
            left: -218px;
            color: #000;
            content: attr(data-date);
            text-align: right;
            font-weight: 100;
            font-size: 0.9em;
            min-width: 120px;
        }

        &:after {
            left: -62px;
            background: #f5c001;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            content: "";
            top: 0;
        }
    }
}

@media screen and (max-width: 700px) {
    .timeline {
        left: 20%;
    }
}
