.contentBlock {
    background-color: #fcf8ef;
    margin-top: 66px;
    width: 100%;
    height: auto;
}

.content {
    padding-top: 12px;
    padding-bottom: 32px;
    height: calc(100% - 44px);
    overflow: auto;
}

img.fullWidth {
    width: 100%;
    height: auto;
}
