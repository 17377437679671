.home.content {
    padding-top: 0;
    overflow: auto;
    height: 100%;
    font-size: 20px;
}

.section.top {
    background-color: #f5c001;
    height: 100%;
    overflow: hidden;
}

.home .header {
    font-size: 48px;
    color: #000;
    font-weight: 700;
    position: fixed;
    background-color: #f5c001;
    height: 100px;
    width: 100%;
}

.home .header:after {
    left: 0;
    content: 'Svatba E+D';
    position: fixed;
    top: 103px;
    width: 100%;
    z-index: 1;
    text-align: center;
    font-weight: 900;
}


.top .image {
    display: block;
    height: calc(100% - 100px);
    width: 100%;
    background-image: url('./images/photo.jpeg');
    background-attachment: fixed;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 100px;
}

.section.info {
    z-index: 100;
    padding: 4px 0 32px;
    position: relative;
    background-color: #fcf8ef;
}

.seznamMap {
    border: none;
    width: 600px;
    height: 400px;
}

@media screen and (max-width: 700px) {
    div .seznamMap {
        width: 90%;
        overflow: hidden;
    }
}
