.gridTile {
    margin: 0 16px 16px 0;
    display: inline-block;
    width: 100%;
    text-align: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #f0ece3;
}

.gridTile .content {
    padding: 8px;
}

.gridTile .title {
    font-size: 22px;
    font-weight:700;
    padding-bottom: 8px;
    text-align: center;
}
