.nav {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.menu {
    overflow: hidden;
    background-color: #f5c001;
    margin: auto;
    display: flex;
}

.menu .element {
    width: 100%;
    text-align: center;
    padding: 14px 0;
    font-size: 22px;
    font-weight: 600;
    align-content: center;
    cursor: pointer;
}

.menu .element.img {
    padding: 4px 0;
}

.menu .element:hover {
    background-color: #dcac00;
    color: black;
}

.menu .element.active {
    color: 	#696969;
}

.expandMenu {
    display: none;
}

.expandMenu .bar1, .expandMenu .bar2, .expandMenu .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
    margin-left: 24px;
}

.responsive .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

.responsive .bar2 {opacity: 0;}

.responsive .bar3 {
    transform: translate(0, -11px) rotate(45deg);
}

.other {
    display: flex;
    width: calc(100% - 120px);
    justify-content: space-evenly;
}

.knot {
    background-size: contain;
    background-repeat: no-repeat;
    height: 58px;
    width: 63px;
    margin: auto;
    color: #999;
}
.knot:after {
    content: url("./images/celticKnot.svg");
}

.active .knot:after {
    filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(236deg) brightness(98%) contrast(83%);
}

.menu .home {
    width: 120px;
}

@media screen and (max-width: 850px) {
    .menu .other {
        display: none;
    }
    .menu .expandMenu {
        display: block;
        flex: 0 0 auto;
        width: 80px;
    }
    .menu .home {
        width: 100%;
        display: flex;
    }
    .menu.responsive .other {
        align-items: normal;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .menu {
        flex-direction: column;
    }
    .knot {
        padding-left: 82px;
    }
}
