.list {
    width: fit-content;
    margin: auto;
}

.list li {
    text-align: left;
    padding-top: 4px;
}

.technical {
    padding: 0 50px;
}
