.image img {
}

.gallery .image {
    margin: 4px;
    width: 1px;
    flex: 1 1 auto;
    height: 100%;
    cursor: pointer;
}
